import { Guide, join } from "../types/guides";
import guideData from "./guides.json";

const guides: Guide[] = guideData.map((g) => g as Guide);
export default guides;

export function isGuidePath(path: string): boolean {
  return guides.some(
    (g) => path === g.slug || path.startsWith(join(g.slug, "/"))
  );
}
