


















import { Component, Prop, Vue } from "vue-property-decorator";
import type { WebsiteContent } from "../types/websiteContent";
import NiceContent from "./NiceContent.vue";

@Component({ components: { NiceContent } })
export default class ContentBlock extends Vue {
  @Prop() content!: WebsiteContent;

  get classList(): string {
    return [
      this.content.isSection ? "section" : "content",
      this.content.componentType,
      this.content.classes,
    ].join(" ");
  }
  get isMessage(): boolean {
    return this.content.componentType === "message";
  }
}
