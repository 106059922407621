




import { Component, Prop, Vue } from "vue-property-decorator";
import type { WebsitePage } from "../types/websitePages";
import WebsiteContentBlock from "./WebsiteContentBlock.vue";

@Component({ components: { WebsiteContentBlock } })
export default class Page extends Vue {
  @Prop() page!: WebsitePage;
}
