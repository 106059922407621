
import { Component, Vue } from "vue-property-decorator";
type range = [number, number];
const screenWidths = {
  mobile: [0, 768] as range,
  tablet: [769, 1023] as range,
  desktop: [1024, 1215] as range,
  widescreen: [1216, 1407] as range,
  fullhd: [1408, 1000000] as range,
};

@Component({})
export default class Base extends Vue {
  isMobile = false;
  isTablet = false;
  mounted() {
    this.detectScreenSize();
    window.addEventListener("resize", this.detectScreenSize);
  }
  private detectScreenSize() {
    const screenWidth = document.documentElement.clientWidth;
    this.isMobile = this.inRange(screenWidth, screenWidths.mobile);
    this.isTablet = this.inRange(screenWidth, screenWidths.tablet);
  }
  private inRange(val: number, range: range): boolean {
    return val >= range[0] && val <= range[1];
  }
}
