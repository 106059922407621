



import { imageInfo } from "../types/websiteContent";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LazyImage from "./LazyImage.vue";

// ref: https://bulma.io/documentation/elements/table/#modifiers
const tableClasses = ["table", "is-striped", "is-hoverable"].join(" ");
const contentfulImageURLPrefix = "//images.ctfassets.net";

@Component
export default class NiceContent extends Vue {
  @Prop() id!: string;
  @Prop() htmlContent!: string;
  @Prop() imagesInfo!: imageInfo[];

  get html(): string {
    // hacky way to prevent browser from actually loading the image's source on mount
    // https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/#aa-step-2-prevent-the-image-from-being-loaded-when-the-component-is-created
    return this.htmlContent
      .replaceAll(
        `<img src="${contentfulImageURLPrefix}`,
        `<img class="img-preload" data-url="${contentfulImageURLPrefix}`
      )
      .replaceAll("<table", `<table class='${tableClasses}'`);
  }

  mounted() {
    this.formatAll();
  }
  @Watch("id")
  async onChange() {
    // components get reused
    // so mounted might not always happen for route_to between pages with contentBlocks >= lastPage.contentBlocks
    // ref: https://router.vuejs.org/guide/advanced/transitions.html#forcing-a-transition-between-reused-views
    await this.$nextTick();
    this.formatAll();
  }

  formatAll() {
    this.formatImages();
  }
  formatImages() {
    // ref: https://levelup.gitconnected.com/vue-js-replace-a-with-router-link-in-dynamic-html-c423beea0d17
    const images = Array.from(this.$el.getElementsByTagName("img"));
    images.forEach((img) => {
      const url = img.dataset["url"];
      if (url) {
        // contentful asset
        const info = this.imagesInfo.find((info) => info.url === url);
        if (info) {
          const lazyImg = new LazyImage({
            propsData: info ? info : { url },
            parent: this,
          });
          lazyImg.$mount(img); // replace img element with new lazyimg
        } // otherwise we just wont show this contentful image, because it doesn't have a valid asset size
      }
    });
  }
}
