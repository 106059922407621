



































import { Component, Prop, Watch } from "vue-property-decorator";
import Base from "../Base.vue";
import MenuItem from "../MenuItem.vue";
import type { Guide } from "../../types/guides";
import { bus, events } from "../../bus";

@Component({ components: { MenuItem } })
export default class GuideSidebar extends Base {
  @Prop() guide!: Guide;
  open = true;

  mounted() {
    bus.$on(events.toggleSidebar, this.toggleSidebar);
  }

  toggleSidebar() {
    this.open = !this.open;
  }

  @Watch("isMobile")
  onMobileChange() {
    this.open = !this.isMobile;
  }

  isActive(slug: string): boolean {
    return this.$route.path === slug;
  }
  isExpanded(slug: string): boolean {
    return this.$route.path.startsWith(slug);
  }
}
