










import { Component, Vue } from "vue-property-decorator";
import TopNavbar from "@/components/TopNavbar.vue";
import Footer from "@/components/Footer.vue";
@Component({ components: { TopNavbar, Footer } })
export default class App extends Vue {
  get columnWidthClass(): string {
    return this.$router.currentRoute.path === "/" ? "is-8" : "is-half";
  }
}
