















import { Component, Prop } from "vue-property-decorator";
import WebsitePage from "../WebsitePage.vue";
import type { Guide } from "../../types/guides";

@Component({})
export default class GuideMainPage extends WebsitePage {
  @Prop() guide!: Guide;
}
