import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotFoundPage from "@/views/404.vue";
import Sandbox from "@/views/Sandbox.vue";
import WebsitePage from "@/common/components/WebsitePage.vue";
import GuideMainPage from "@/common/components/guides/GuideMainPage.vue";
import GuideWebsitePage from "@/common/components/guides/GuideWebsitePage.vue";
import GuideSidebar from "@/common/components/guides/GuideSidebar.vue";
import websitePages from "@/common/data/websitePages";
import guides from "@/common/data/guides";

Vue.use(VueRouter);
const guideRoutes: RouteConfig[] = guides.flatMap((g) => {
  return [
    {
      path: g.slug,
      components: {
        default: GuideMainPage,
        leftSidebar: GuideSidebar,
      },
      props: {
        default: { page: g.mainPage, guide: g },
        leftSidebar: { guide: g },
      },
    } as RouteConfig,
  ].concat(
    g.sections.flatMap((s) =>
      s.children
        .flatMap((p) => {
          return [p].concat(p.children);
        })
        .map((p) => {
          return {
            path: p.slug,
            components: {
              default: GuideWebsitePage,
              leftSidebar: GuideSidebar,
            },
            props: {
              default: { page: p.page, guidePage: p, guide: g },
              leftSidebar: { guide: g },
            },
          };
        })
    )
  );
});

const pageRoutes = websitePages.map((p): RouteConfig => {
  return {
    path: p.slug === "index" ? "/" : `/${p.slug}`,
    component: WebsitePage,
    props: { page: p },
  };
});

const routes: Array<RouteConfig> = [
  { path: "/sandbox-testing", component: Sandbox },
  ...guideRoutes,
  ...pageRoutes,
  { path: "*", component: NotFoundPage },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
  // ref: https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      // back button
      return savedPosition;
    }
    // scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;
